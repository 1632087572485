
import _ from 'lodash';

export function gotoLoginIfNotLogedIn(logedIn) {
    if (!logedIn) {
        document.location.href = '#/login';
    }
}

export function gotoCalendar() {
    document.location.href = '/#/calendar';
}

export function goToCommand(history) {
    history.push('/command');
}

export const ifHasPermission = (component, noPermissoinComponent, hasPermission) => (hasPermission ? component : noPermissoinComponent);
export const getHourAsStr = (hour) => `${_.padStart(hour.hour, 2, '0')}:${_.padStart(hour.min, 2, '0')}`;
export const notifyUser = (message) => alert(message);
export function buildBackendErrorSaga(successDefaultMessage, errorDefaultMessage, successCallback = _.noop) {

    return function* processBackendError({
        payload
    }) {
        if (payload.status === 'error') {
            yield notifyUser(payload.message || errorDefaultMessage);
        } else {
            yield notifyUser(successDefaultMessage);
            yield successCallback();
        }
    };
}
export default {
    gotoLoginIfNotLogedIn,
    getHourAsStr,
    notifyUser,
    buildBackendErrorSaga,
};

