import {
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';

import { LOGIN_SUCCESS_ACTION } from './login';
import { RELOAD_EVENTS } from './calendar';
import { CLOSE_MODAL } from './scheduler';

import {
    otherApis,
} from '../../../services/api';

import {
    buildBackendErrorSaga,
    gotoCalendar,
} from '../../../services/util';
import {
    serverInteraction,
} from '../sauce';

export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';

const paymentState = {
    paymentTypes: [],
    customer: null,
};

function setCustomerReducer(state, event) {
    return {
        ...state,
        customer: event.payload.customer,
    };
}

export function setCustomerAction(customer) {
    return {
        type: SET_CURRENT_CUSTOMER,
        payload: {
            customer,
        },
    };
}

const paymentTypeRedux = serverInteraction('PAYMENT_TYPE', otherApis.get, 'cash/getPaymentTypes');

export const {
    PAYMENT_TYPE_ACTION,
    PAYMENT_TYPE_SUCCESS,
    PAYMENT_TYPE_ERROR,
    paymentTypeAction,
    paymentTypeReducer,
    paymentTypeSuccessReducer,
    paymentTypeErrorReducer,
    paymentTypeSaga,
} = paymentTypeRedux;

const openCashierRedux = serverInteraction('OPEN_CASHIER', otherApis.get, 'cash/openCheckouts');

export const {
    OPEN_CASHIER_ACTION,
    OPEN_CASHIER_SUCCESS,
    OPEN_CASHIER_ERROR,
    openCashierAction,
    openCashierReducer,
    openCashierSuccessReducer,
    openCashierErrorReducer,
    openCashierSaga,
} = openCashierRedux;

const processPaymentRedux = serverInteraction('PROCESS_PAYMENT', otherApis.post, 'cash/processPayment');

export const {
    PROCESS_PAYMENT_ACTION,
    PROCESS_PAYMENT_SUCCESS,
    PROCESS_PAYMENT_ERROR,
    processPaymentAction,
    processPaymentReducer,
    processPaymentSuccessReducer,
    processPaymentErrorReducer,
    processPaymentSaga,
} = processPaymentRedux;


const removePaymentRedux = serverInteraction('PAYMENT_DELETE', otherApis.get, 'cash/removePayment/:command/:customer/:date', false, true);

export const {
    PAYMENT_DELETE_ACTION,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_ERROR,
    paymentDeleteAction,
    paymentDeleteReducer,
    paymentDeleteSuccessReducer,
    paymentDeleteErrorReducer,
    paymentDeleteSaga,
} = removePaymentRedux;


const reducerMap = {
    [PAYMENT_TYPE_SUCCESS]: (state, action) => ({ ...state, paymentTypes: action.payload }),//Call success if loading is needed
    [PAYMENT_TYPE_ERROR]: paymentTypeErrorReducer,

    [PROCESS_PAYMENT_ACTION]: processPaymentReducer,
    [PROCESS_PAYMENT_ERROR]: processPaymentErrorReducer,

    [OPEN_CASHIER_SUCCESS]: (state, action) => ({ ...state, openCashiers: action.payload, selectedCashier: action.payload[0] }),
    [OPEN_CASHIER_ERROR]: openCashierErrorReducer,

    [PROCESS_PAYMENT_SUCCESS]: processPaymentSuccessReducer,

    [PAYMENT_DELETE_ACTION] : paymentDeleteReducer,
    [PAYMENT_DELETE_SUCCESS]: paymentDeleteSuccessReducer,
    [PAYMENT_DELETE_ERROR]: paymentDeleteErrorReducer,

    [SET_CURRENT_CUSTOMER]: setCustomerReducer,
};


export default function reducer(state = paymentState, action) {
    const actionFn = reducerMap[action.type];
    if (actionFn) {
        return actionFn(state, action) || state;
    }
    return state;
}

const processPaymentErrorSaga = buildBackendErrorSaga('Pagamento efeturado com sucesso!', 'Erro ao efetuar pagamento!', function* successCallback(){
    yield gotoCalendar();
});
const paymentDeleteErrorSaga = buildBackendErrorSaga('Pagamento excluido com sucesso!', 'Erro ao excluido pagamento!', 
    function* successCallback() {
        yield put({
            type: CLOSE_MODAL,
            payload: {
                closeModal: true,
            },
        });
        yield put({
            type: RELOAD_EVENTS,
        });
    }
);

export function* paymentSagas() {
    yield all([
        yield takeEvery(PROCESS_PAYMENT_ACTION, processPaymentSaga),
        yield takeEvery(PROCESS_PAYMENT_ERROR, processPaymentErrorSaga),
        yield takeEvery(PROCESS_PAYMENT_SUCCESS, processPaymentErrorSaga),

        yield takeEvery(PAYMENT_DELETE_ACTION, paymentDeleteSaga),
        yield takeEvery(PAYMENT_DELETE_ERROR,  paymentDeleteErrorSaga),
        yield takeEvery(PAYMENT_DELETE_SUCCESS, paymentDeleteErrorSaga),

        yield takeEvery(PAYMENT_TYPE_ACTION, paymentTypeSaga),
        yield takeEvery(LOGIN_SUCCESS_ACTION, paymentTypeSaga),
        yield takeEvery(LOGIN_SUCCESS_ACTION, openCashierSaga),
        yield takeEvery(OPEN_CASHIER_ACTION, openCashierSaga),
    ]);
}
