import NunDb from 'nun-db';
import {
    LOGIN_SUCCESS_ACTION,
} from './redux/store/ducks/login';
import {
    upateTreatmentsAction,
} from './redux/store/ducks/calendar';

const dbStore = {
    nun: null,
};

const dbMiddleware = (store) => (next) => (action) => {
    next(action);
    const company = action.payload ? action.payload.company : action.company;
    if (action.type === LOGIN_SUCCESS_ACTION) {
        const {
            id,
            nunDbPwd,
        } = company;

        const nun = new NunDb('wss://ws.nundb.org', `org-${id}`, nunDbPwd);
        nun.watch('lastTreatment', (event) => {
            store.dispatch(upateTreatmentsAction(event.value));
        });
        dbStore.nunDb = nun;
    }
};

export {
    dbMiddleware,
    dbStore,
};
