import React from 'react';
import Typography from '@material-ui/core/Typography';
import WeekCalendar from 'react-week-calendar';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { changeDateAction, closeNoticationAction } from '../redux/store/ducks/calendar';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import EbelleEvent from './calendar/Event';
import EbelleModal from './calendar/Modal';

import 'moment/locale/pt-br';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        changeDateAction: (date) => dispatch(changeDateAction(date)),
        closeNotication: () => dispatch(closeNoticationAction()),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        selectedIntervals: state.calendarState.events,
        calendatDate: state.calendarState.calendarDate,
        showNotification: state.calendarState.showNotification,
        notificationType: state.calendarState.notificationType || 'success',
        user: state.loginState.user,
        company: state.loginState.company,
    };
}

function Alert(props) {
    // eslint-disable-next-line
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Calendar({
    logedIn,
    scheduleSuccess,
    calendatDate,
    changeDateAction: changeDateActionFn,
    showNotification,
    notificationType,
    closeNotication,
    company,
    selectedIntervals,
    user,
}) {
    const classes = useStyles();
    if (!logedIn || scheduleSuccess) {
        document.location.href = '/#/login';
        return <span />;
    }

    return (
      <span>
        <Typography variant="h5" gutterBottom>
          Agenda
        </Typography>
        <Grid container>
          <Grid item xs={4}  justify="center" alignItems="center">
             <Avatar alt={user.name} src={`https://gerirme.app/images/business_pattern/${user.imagethumb}`} />
            <Typography variant="subtitle1" gutterBottom>
                {user.name}
            </Typography>
          </Grid>
          <Grid item xs={6}  justify="center" alignItems="center">
            <form className={classes.container} noValidate>
                  <TextField
                      id="calendar-date"
                      label="Data"
                      type="date"
                      defaultValue={moment(calendatDate).format('YYYY-MM-DD')}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => changeDateActionFn(event.target.value)}
                  />
            </form>
           </Grid>
        </Grid>
        <Snackbar open={!!showNotification} autoHideDuration={6000} onClose={closeNotication}>
          <Alert severity={notificationType} onClose={closeNotication}>
            {showNotification}
          </Alert>
        </Snackbar>
        <WeekCalendar
          firstDay={calendatDate}
          startTime={moment({ h: company.calendarStart, m: 0 })}
          endTime={moment({ h: company.calendarEnd, m: 0 })}
          scaleUnit={company.calendarInterval}
          scaleHeaderTitle="Hora"
          cellHeight={50}
          numberOfDays={3}
          selectedIntervals={selectedIntervals}
          eventComponent={EbelleEvent}
          modalComponent={EbelleModal}
          onIntervalSelect={(e) => console.log(e)}
        />
      </span>
    );
}

Calendar.defaultProps = {
    logedIn: null,
    scheduleSuccess: null,
    calendatDate: null,
    changeDateAction: null,
    showNotification: null,
    notificationType: null,
    closeNotication: null,
    company: null,
    selectedIntervals: [],
};

Calendar.propTypes = {
    customers: PropTypes.array,
    logedIn: PropTypes.bool,
    scheduleSuccess: PropTypes.bool,
    calendatDate: PropTypes.object,
    changeDateAction: PropTypes.func,
    showNotification: PropTypes.bool,
    notificationType: PropTypes.string,
    closeNotication: PropTypes.func,
    company: PropTypes.object,
    user: PropTypes.object,
    selectedIntervals: PropTypes.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Calendar);
