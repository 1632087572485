import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from '@material-ui/core/TextField';


import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import MoneyIcon from '@material-ui/icons/Money';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {
    customerSearchAction,
    addDetailAction,
    deleteDetailAction,
    customerAddAction,
    restoreCustomerSearchAction,
    rescheduleAction,
    nextCommandIdAction,
    restoreModalState,
    setCurrentCommandAction,
} from '../redux/store/ducks/scheduler';

import {
    TreatmentStatus
} from '../redux/store/ducks/calendar';

import {
    processPaymentAction,
} from '../redux/store/ducks/payment';

import {
    gotoLoginIfNotLogedIn,
    ifHasPermission,
} from '../services/util';

import {
    formatDateToServer,
} from '../services/date';

import CustomerSelect from './customer/CustomerSelect';


function mapDispatchToProps(dispatch) {
    return {
        customerSearchAction: (search) => dispatch(customerSearchAction({ name: search })),
        addDetailAction: (payload) => dispatch(addDetailAction(payload)),
        deleteDetailAction: (payload) => dispatch(deleteDetailAction(payload)),
        getHoursAction: () => console.log('todo'),
        customerAddAction: (payload) => dispatch(customerAddAction(payload)),
        restoreCustomerSearchAction: (customers) => dispatch(restoreCustomerSearchAction(customers)),
        rescheduleAction: (payload) => dispatch(rescheduleAction(payload)),
        nextCommandIdActionFn: (payload) => dispatch(nextCommandIdAction(payload)),
        setCurrentCommandActionFn: (command) => dispatch(setCurrentCommandAction(command)),
        restoreModalState: () => dispatch(restoreModalState()),
        processPaymentActionFn: (data) => dispatch(processPaymentAction(data)),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        activities: state.calendarState.activities || [],
        customerInCalendar: state.calendarState.customerInCalendar || [],
        isCalendarInitializing: state.calendarState.initializing,
        customers: state.scheduleState.customers || [],
        deletingDetailId: state.scheduleState.id,
        treatments: state.calendarState.events,
        paymentTypes: state.paymentState.paymentTypes,
        selectedCashier: state.paymentState.selectedCashier,
        openCashierLoading: state.paymentState.openCashierLoading,
        hours: state.scheduleState.hours || [],
        scheduleSuccess: state.scheduleState.scheduleSuccess,
        searching: state.scheduleState.customerSearchLoading,
        addingCustomer: state.scheduleState.customerAddLoading,
        deleteDetailLoading: state.scheduleState.deleteDetailLoading,
        rescheduleLoading: state.scheduleState.rescheduleLoading,
        scheduleLoading: state.scheduleState.scheduleLoading,
        nextCommandIdLoading: state.scheduleState.nextCommandIdLoading,
        nextCommandId: state.scheduleState.nextCommandId,
        commandNumber: state.scheduleState.currentCommand,
        customer: state.paymentState.customer,
        processPaymentLoading: state.paymentState.processPaymentLoading,
        user: state.loginState.user,
        permissions: state.loginState.permissions,
    };
}


const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    formControlCustomer: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


function Command({
    deletingDetailId,
    logedIn, 
    user, 
    permissions,
    customer: propCustomer, 
    treatments = [],
    activities = [],
    paymentTypes = [],
    customerInCalendar = [],
    isCalendarInitializing,
    scheduleLoading,
    nextCommandIdLoading,
    addDetailAction,
    deleteDetailAction,
    deleteDetailLoading,
    selectedCashier,
    openCashierLoading,
    processPaymentLoading,
    processPaymentActionFn,
    nextCommandIdActionFn,
    setCurrentCommandActionFn,
    commandNumber,
}) {
    gotoLoginIfNotLogedIn(logedIn);
    const [customer, setCustomer] = useState(propCustomer);
    const [paymentValue, setPaymentValue] = useState(0);
    const [service, setSevice] = useState(_.get(paymentTypes, '0.id'));
    const [paymentTypeSelected, setPaymentTypeSelected] = useState(_.get(paymentTypes, '0.id'));
    const [payments, setPayments] = useState([]);
    const [search, setSearch] = useState('');
    const [treatment, setTreatment] = useState(null);
    const classes = useStyles();

    const customerTreatments = treatments.filter(treatment => customer && treatment.customerId === customer.id );
    const currentTreatment = customerTreatments[0];

    if(currentTreatment && currentTreatment != treatment) {
        setTreatment(currentTreatment);
        if(currentTreatment.command && currentTreatment.command != "0") {
            setCurrentCommandActionFn(currentTreatment.command);
        } else {
            setCurrentCommandActionFn(null);
            nextCommandIdActionFn({ 
                date: formatDateToServer (currentTreatment.start),
                shouldSetCurrentCommand: true,
            });
        }
    }

    if (!user || isCalendarInitializing || openCashierLoading) {
        return <CircularProgress size={50} />;
    }

    const totalPrice = customerTreatments
        .map(_ => _.details)
        .reduce((acc, current)=>(acc.concat(current)),[])
        .reduce((acc, detail)=> acc + parseFloat(detail.price),0);

    const totalPaied = payments.reduce((acc, pay)=> acc+ parseFloat(pay.value), 0)

    const valueToShow = paymentValue || (totalPrice - totalPaied);


    return (
    <span>
        <Grid container spacing={3}>
          <Grid item xs={12}  justify="center" alignItems="center">
            <center>
                <Typography variant="h3" component="h3" gutterBottom>
                   Comanda
                </Typography>
            </center>
          </Grid>
          <Grid item xs={12}>
              {
                  nextCommandIdLoading ? <CircularProgress /> :
              <TextField label="Numero da comanda" 
                  id="command-number"
                  value={commandNumber}
                  onChange={(e) => setCurrentCommandActionFn(e.target.value)}
              />
              }

          </Grid>
          <Grid item xs={12}>
            <CustomerSelect
                customer={customer}
                setCustomer={(customer) => {
                    setCustomer(customer);
                    if(!customer) {
                        setTreatment(null);
                        setCurrentCommandActionFn(0);
                    }
                }}
                customersFixList={customerInCalendar.filter(customer => customer.treatmentStatus != TreatmentStatus.paid)}
                search={search}
                setSearch={setSearch}
                classes={classes}
                setFocusField={() => {}}
            />
           </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
               Serviço
            </Typography>
            <Select
                id="activity"
                disabled={scheduleLoading || !customer}
                value={service}
                label="Selecione o serviço"
                onChange={(event) => {
                    setSevice(event.target.value)
                }}
            >
              <MenuItem />
                {activities.map((activity) => <MenuItem key={activity.id} value={activity.id}>{activity.name}</MenuItem>)}
             </Select>
          </Grid>
          <Grid item xs={6}>
             <Button
                 id="add-activity"
                 variant="contained"
                 color="primary"
                 className={classes.submit}
                 disabled={processPaymentLoading || scheduleLoading || !customer || !service }
                 onClick={
                     () => addDetailAction({
                         customerId: customer.id,
                         company: user.company,
                         unit: user.unit,
                         date: formatDateToServer(treatment.start),
                         hour_start: treatment.end.format("HH:mm"),
                         activity: service,
                         user: user.id,
                         logedUser: user,
                         id: treatment.id,
                     })
                 }
             >
                 Adicionar
             </Button>
          </Grid>
          <Grid item xs={12}>
               <List dense className={classes.root}>
                   {customerTreatments.map((treatment) => {
                       const treatmentDetails = treatment.details;
                       return (
                           treatmentDetails.map((detail) =>  {
                               const id = detail.id;
                               const isDeleting = id == deletingDetailId;
                               if(isDeleting) {
                                   return <ListItem key={id}> 
                                            <CircularProgress  size={20}/> 
                                            <ListItemText id={`id-${id}`} classes="treatment" primary={`Excluindo...`} />
                                       </ListItem>
                               } {
                                   return <span key={id}>
                                           <ListItem key={id}>
                                           <ListItemText id={`id-${id}`} classes="treatment" primary={`${detail.activity}`} />
                                          <IconButton
                                              disabled={deleteDetailLoading}
                                              onClick={()=> deleteDetailAction({ id: detail.id })}
                                              className="deleteButton"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                       </ListItem>
                                       <Divider component="li" />
                                  </span>
                               }
                           }
                           )
                       );
                   })
                   }
              </List>
            </Grid>
            {ifHasPermission(<Grid container spacing="4" xs={12}>
              <Grid item xs={6}>
                 <FormControl fullWidth className={classes.margin}>
                   <InputLabel htmlFor="standard-adornment-amount">Forma de Pagamento</InputLabel>
                   <Select
                       id="payment-type"
                       disabled={scheduleLoading || !customer}
                       value={paymentTypeSelected}
                       label="Selecione uma forma de pagamento"
                       onChange={(event) => {
                           setPaymentTypeSelected(event.target.value)
                       }}
                   >
                     <MenuItem />
                       {paymentTypes.map((paymentType) => <MenuItem key={paymentType.id} value={paymentType.id}>{paymentType.name}</MenuItem>)}
                    </Select>
                 </FormControl>
              </Grid>
              <Grid item xs={6}>
                 <FormControl fullWidth className={classes.margin}>
                  <CurrencyTextField
                      id="payment-value"
                      label="Valor"
                      variant="standard"
                      value={valueToShow}
                      currencySymbol="R$"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      onChange={(event, value)=> setPaymentValue(value)}
                  />
                 </FormControl>
               </Grid>
              <Grid item xs={6}>
                 <Button
                     id="add-payment"
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     disabled={processPaymentLoading || scheduleLoading || !customer || !paymentTypeSelected }
                     onClick={
                         () => {
                             setPayments(payments.concat([{
                                 "id": 0,
                                 "value": parseFloat(paymentValue || valueToShow),
                                 "typePayment": paymentTypeSelected,
                                 "removed": false,
                                 "dateDetail": treatment.start.valueOf(),
                                 "desc": paymentTypes.find( _ => _.id == paymentTypeSelected).name,// Should not be needed
                                 "accept_installment": false,
                                 "dateDetailStr": formatDateToServer(treatment.start.valueOf()),
                                 "valueToPoints": 0
                             }]));
                             setPaymentValue(null);
                         }
                     }
                 >
                     Addicionar PG
                 </Button>
              </Grid>
              <Grid item xs={12}>
                  <List dense className={classes.root}>
                      {payments.map((payment, key) => {
                          return <span key={key}>
                              <ListItem key={key}>
                                  <ListItemText id={`id-${key}`} classes="treatment" primary={`${payment.desc}`}  secondary={`R$ ${payment.value.toFixed(2, ',')}`}/>
                                  <IconButton
                                      onClick={()=> {
                                          const newPayments = payments.concat([]);
                                          newPayments.splice(key,1);
                                          setPayments(newPayments);
                                      }}
                                      className="deleteButton">
                                      <DeleteIcon />
                                  </IconButton>
                              </ListItem>
                              <Divider component="li" />
                          </span>
                      })
                      }
                  </List>
                </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={1}>
                 <Button
                     id="payment"
                     variant="contained"
                     className={classes.submit}
                     disabled={ processPaymentLoading || scheduleLoading || !paymentTypeSelected  || !customer || ((payments.length == 0 && valueToShow != totalPrice) || ( payments.length != 0 && totalPaied !== totalPrice) )}
                     onClick={
                         () => {
                             if(!selectedCashier) {
                                 alert("Abra um caixa antes de fazer o pagamento!");
                                 return;
                             }
                             const paymentDetails = payments.concat([]);
                             if(payments.length == 0) {
                                 paymentDetails.push({
                                     "id": 0,
                                     "value": parseFloat(paymentValue || valueToShow),
                                     "typePayment": paymentTypeSelected,
                                     "removed": false,
                                     "dateDetail": treatment.start.valueOf(),
                                     "desc": paymentTypes.find( _ => _.id == paymentTypeSelected).name,// Should not be needed
                                     "accept_installment": false,
                                     "dateDetailStr": formatDateToServer(treatment.start.valueOf()),
                                     "valueToPoints": 0
                                 });
                                 setPayments(paymentDetails);
                             }

                             const paymentRequest = {
                                 treatments: customerTreatments.map( treatment => ({
                                     ...treatment,
                                     "dateTreatment": treatment.start.valueOf(),
                                     "treatmentStatus": treatment.status,
                                     "customerValueInPoints": 0,
                                     "customerValueInAccount": 0,// @todo check customer account service
                                     "userId":  user.id,
                                     "unit": user.unit,
                                     "removed": false,
                                     "ignored": false,
                                     "activitys": treatment.details.map(detail => ({
                                         ...detail,
                                         "price": parseFloat(detail.price),
                                         "activityType": "activity",
                                         "auxiliar": parseInt(detail.auxiliar || 0),
                                         "animal": parseInt(detail.animal || 0),
                                         "offsale": parseInt(detail.offsale || 0),
                                         "giftId": "",
                                         "amount": 1,
                                         "for_delivery": false,
                                         "parentBom": 0,
                                         "ignored": false,
                                         "obs": "",
                                         "removed": false
                                     })),
                                     "details" : null,
                                 })),
                                 payments: paymentDetails,
                                 command: commandNumber,
                                 dataTreatments: formatDateToServer(treatment.start),
                                 cashier: selectedCashier.id,//Needed
                                 status2:"",//todo
                                 valueToPoints: 0.0
                             };
                             processPaymentActionFn({ data: JSON.stringify(paymentRequest) });
                         }
                     }
                     endIcon={<MoneyIcon/>}
                 >
                     Pagar
                 </Button>
              </Grid>
             </Grid>, <Grid></Grid>, permissions.isFinancialManager)
            }
        </Grid>
        </span>
    );
}

Command.propTypes = {
    deletingDetailId:  PropTypes.string,
    commandNumber:  PropTypes.string,
    logedIn: PropTypes.bool, 
    nextCommandId: PropTypes.bool, 
    nextCommandIdLoading: PropTypes.bool, 
    user: PropTypes.object,
    permissions: PropTypes.object,
    customer: PropTypes.object,
    treatments: PropTypes.array,
    paymentTypes: PropTypes.array,
    activities: PropTypes.array,
    customerInCalendar: PropTypes.array,
    isCalendarInitializing: PropTypes.bool,
    processPaymentLoading: PropTypes.bool,
    scheduleLoading: PropTypes.bool,
    addDetailAction: PropTypes.func,
    processPaymentActionFn: PropTypes.func,
    setCurrentCommandActionFn: PropTypes.func,
    nextCommandIdActionFn: PropTypes.func,
    deleteDetailAction: PropTypes.func,
    deleteDetailLoading: PropTypes.bool,
    selectedCashier: PropTypes.object,
    openCashierLoading: PropTypes.bool,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Command);
