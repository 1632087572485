import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const propTypes = {
    start: PropTypes.date,
    end: PropTypes.date,
    value: PropTypes.string,
};

const useStyles = makeStyles(() => ({
    interval: {
        backgroundColor: 'gray',
        color: '#fff',
    },
    'pre-scheduled': {
        backgroundColor: 'rgb(43, 178, 208);',
        color: '#ffff',
    },
    scheduled: {
        backgroundColor: 'rgb(104, 161, 229);',
        color: '#fff',
    },
    confirmed: {
        backgroundColor: 'rgb(244, 72, 255)',
        color: '#fff',
    },
    arived: {
        backgroundColor: 'rgb(224, 217, 2)',
        color: '#fff',
    },
    payed: {
        backgroundColor: 'rgb(0, 128, 0)',
        color: '#fff',
    },
}));

const classMap = {
    0: 'scheduled',
    7: 'pre-scheduled',
    6: 'confirmed',
    2: 'arived',
    4: 'payed',
};
function getEventClass(classes, props) {
    const componentClasses = ['event'];
    if (props.is_employee_hour) {
        componentClasses.push(classes.interval);
    } else {
        componentClasses.push(classes[classMap[props.status]]);
    }
    return componentClasses.reverse().join(' ');
}

function EbelleEvent(props) {
    const classes = useStyles();
    const {
        start,
        end,
        value,
    } = props;
    return (
      <div className={getEventClass(classes, props)}>
        <span>{`${start.format('HH:mm')} - ${end.format('HH:mm')}`}</span>
        <br />
        {value}
      </div>
    );
}
EbelleEvent.propTypes = propTypes;

export default EbelleEvent;
