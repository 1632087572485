import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';

import { getCommitionAction } from '../redux/store/ducks/commision';

import CommissionList from './CommissionList';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function mapDispatchToProps(dispatch) {
    return {
        getCommitionAction: (payload) => dispatch(getCommitionAction(payload)),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        calendatDate: state.calendarState.calendarDate,
        user: state.loginState.user,
        company: state.loginState.company,
        commisions: state.commisionState.commisions,
        totalCommision: state.commisionState.totalCommision,
        totalReceived: state.commisionState.totalReceived,
        toReceive: state.commisionState.toReceive,
    };
}

function CommisionCard({
    logedIn,
    scheduleSuccess,
    getCommitionAction: getCommitionActionFn,
    user,
    totalReceived,
    totalCommision,
    commisions,
}) {
    if (!logedIn || scheduleSuccess) {
        document.location.href = '/#/login';
    }

    const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('month').toDate());

    const classes = useStyles();

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.text} variant="span" gutterBottom>
            <form className={classes.container} noValidate>
              <TextField
                id="start-date"
                label="Start"
                type="date"
                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => setStartDate(event.target.value)}
              />

              <TextField
                id="end-date"
                label="End"
                type="date"
                defaultValue={moment(endDate).format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => setEndDate(event.target.value)}
              />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                id="commission-search"
                className={classes.submit}
                onClick={() => {
                      getCommitionActionFn({
                          logedUser: user,
                          startDate,
                          endDate,

                      });
                  }}
              >
                Buscar
              </Button>
              <br />
              <br />
            </form>
          </Typography>
          <Typography className={classes.text} variant="h5" gutterBottom>
            Relatorio de comissão
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Liguido a receber
          </Typography>
          <Typography variant="h5" component="h2">
            R$
            {' '}
            {(totalCommision - totalReceived).toFixed(2)}
          </Typography>

          <Typography className={classes.pos} color="textSecondary">
            Comisões:
          </Typography>
          <Typography variant="body2" component="p">
            R$
            {' '}
            {totalCommision.toFixed(2)}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Vales:
          </Typography>
          <Typography variant="body2" component="p">
            R$
            {' '}
            {totalReceived.toFixed(2)}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Ver detalhes </Button>
        </CardActions>
        <CommissionList commisions={commisions} />
      </Card>
    );
}

CommisionCard.defaultProps = {
    logedIn: false,
    scheduleSuccess: false,
    getCommitionAction: null,
    user: null,
    totalReceived: 0.0,
    totalCommision: 0.0,
    commisions: [],
};

CommisionCard.propTypes = {
    logedIn: PropTypes.bool,
    scheduleSuccess: PropTypes.bool,
    getCommitionAction: PropTypes.func,
    user: PropTypes.object,// eslint-disable-line
    totalReceived: PropTypes.number,
    totalCommision: PropTypes.number,
    commisions: PropTypes.array, // eslint-disable-line
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommisionCard);
