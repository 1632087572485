import React from 'react';
import PropTypes from 'prop-types';
import Schedule from '../Schedule';

const propTypes = {
    start: PropTypes.object.isRequired,// eslint-disable-line
    end: PropTypes.object.isRequired, // eslint-disable-line
    value: PropTypes.string, // eslint-disable-line
    onRemove: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,// eslint-disable-line
    actionType: PropTypes.string, // eslint-disable-line
    customerId: PropTypes.any, // eslint-disable-line
    status: PropTypes.any, // eslint-disable-line
    title: PropTypes.any, // eslint-disable-line
    customerName: PropTypes.any, // eslint-disable-line
    id: PropTypes.any, // eslint-disable-line
    command: PropTypes.string, // eslint-disable-line
};

const defaultProps = {
    value: '',
};

class Modal extends React.Component {
    handleRemove() {
        const { onRemove } = this.props;
        onRemove();
    }

    renderText() {
        const {
            start,
            end,
        } = this.props;

        if (start.isSame(end, 'day')) {
            return (<span>{`${start.format('Do MMM., HH:mm')} - ${end.format('HH:mm')}`}</span>);
        }
        return (<span>{`${start.format('Do MMM.')} - ${end.format('Do MMM.')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`}</span>);
    }

    render() {
        const {
            customerId, 
            customerName, 
            start, 
            title, 
            status, 
            id,
            command,
        } = this.props;
        const customer = customerId
            ? { id: customerId, name: customerName }
            : null;
        return (
          <div className="customModal">
            <div className="customModal__text">
              {' '}
              Agendamento
              {this.renderText()}
            </div>
            <Schedule command={command} date={start} hour={start.format('HH:mm')} customer={customer} id={id} servicesDescription={title && title.split('<br/>')[1]} status={status} closeModal={this.handleRemove.bind(this)} />
          </div>
        );
    }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default Modal;
