export const APP_INIT_ACTION = 'APP_INIT';
export function initAction() {
    return {
        type: APP_INIT_ACTION,
    };
}

export default {
    initAction,
};
