import {
    put,
    takeEvery,
    debounce,
    all,
} from 'redux-saga/effects';

import API, {
    otherApis,
} from '../../../services/api';
import {
    serverInteraction,
} from '../sauce';
import {
    RELOAD_EVENTS,
    CALENDAR_EVENTS_LOAD_START_ACTION,
} from './calendar';
import {
    buildBackendErrorSaga,
} from '../../../services/util';

export const SET_CURRENT_COMMAND = 'SET_CURRENT_COMMAND';

const schedulerState = {
    search: '',
    customers: [],
    hours: [],
    selectedCustomer: null,
    closeModal: false,
    nextCommandId: 0,
    currentCommand: 0,
};

const RESTORE_CUSTOMER_ACTION = 'RESTORE_CUSTOMER_ACTION';

export const CLOSE_MODAL = 'CLOSE_MODAL';

// eslint-disable-next-line
const notifyUser = (message) => alert(message);


function setCurrentCommandReducer(state, event) {
    return {
        ...state,
        currentCommand: event.payload.command,
    };
}

export function setCurrentCommandAction(command) {
    return {
        type: SET_CURRENT_COMMAND,
        payload: {
            command,
        },
    };
}


function modalReducer(state, event) {
    return {
        ...state,
        closeModal: (event.payload && event.payload.closeModal) || event.closeModal,
    };
}

export function closeModalAction() {
    return {
        type: CLOSE_MODAL,
        payload: {
            closeModal: true,
        },
    };
}

export function restoreModalState() {
    return {
        type: CLOSE_MODAL,
        payload: {
            closeModal: false,
        },
    };
}

const customerSearchRedux = serverInteraction('CUSTOMER_SEARCH', API.post, 'customer');

export const {
    CUSTOMER_SEARCH_ACTION,
    CUSTOMER_SEARCH_SUCCESS,
    CUSTOMER_SEARCH_ERROR,
    customerSearchAction,
    customerSearchReducer,
    customerSearchSuccessReducer,
    customerSearchErrorReducer,
    customerSearchSaga,
} = customerSearchRedux;

const addCustomerRedux = serverInteraction('CUSTOMER_ADD', otherApis.post, 'calendar/customer/add');

export const {
    CUSTOMER_ADD_ACTION,
    CUSTOMER_ADD_SUCCESS,
    CUSTOMER_ADD_ERROR,
    customerAddAction,
    customerAddReducer,
    // customerAddSuccessReducer,
    customerAddErrorReducer,
    customerAddSaga,
} = addCustomerRedux;


const nextCommandIdRedux = serverInteraction('NEXT_COMMAND_ID', 
    otherApis.get, 
    'calendar/:date/getNextCommandId', 
    true, true);

function nextCommandIdSuccessReducer(state, action) {
    return {
        ...state,
        nextCommandIdLoading: false,
        nextCommandId: action.payload,
        shouldSetCurrentCommand: false,
        currentCommand: state.shouldSetCurrentCommand ? action.payload : state.currentCommand,
    };
}

export const {
    NEXT_COMMAND_ID_ACTION,
    NEXT_COMMAND_ID_SUCCESS,
    NEXT_COMMAND_ID_ERROR,
    nextCommandIdAction,
    nextCommandIdReducer,
    //nextCommandIdSuccessReducer,
    nextCommandIdErrorReducer,
    nextCommandIdSaga,
} = nextCommandIdRedux;


const removeTreatmentRedux = serverInteraction('TREATMENT_DELETE', otherApis.delete, 'treatment/:id', false, true);

export const {
    TREATMENT_DELETE_ACTION,
    TREATMENT_DELETE_SUCCESS,
    TREATMENT_DELETE_ERROR,
    treatmentDeleteAction,
    treatmentDeleteReducer,
    treatmentDeleteSuccessReducer,
    treatmentDeleteErrorReducer,
    treatmentDeleteSaga,
} = removeTreatmentRedux;


function customerAddSuccessReducer(state, action) {
    return {
        ...state,
        customerAddLoading: false,
        customers: [{
            autoSelect: true,
            name: state.name,
            id: action.payload,
        }],
    };
}

function restoreCustomerSearchReducer(state, action) {
    return {
        ...state,
        customers: action.customers,
    };
}

const schedulerRedux = serverInteraction('SCHEDULE', API.post, 'schedule', false);

export const {
    SCHEDULE_ACTION,
    SCHEDULE_SUCCESS,
    SCHEDULE_ERROR,
    scheduleAction,
    scheduleReducer,
    scheduleErrorReducer,
    scheduleSuccessReducer,
    scheduleSaga,
} = schedulerRedux;

const addDetailRedux = serverInteraction('ADD_DETAIL', API.post, 'add-detail', false);

export const {
    ADD_DETAIL_ACTION,
    ADD_DETAIL_SUCCESS,
    ADD_DETAIL_ERROR,
    addDetailAction,
    addDetailReducer,
    addDetailErrorReducer,
    addDetailSuccessReducer,
    addDetailSaga,
} = addDetailRedux;

const deleteDetailRedux = serverInteraction('DELETE_DETAIL', API.post, 'delete-detail', true);// Do not panic ... lift has some issue with the delete method. That is why we are using post here

export const {
    DELETE_DETAIL_ACTION,
    DELETE_DETAIL_SUCCESS,
    DELETE_DETAIL_ERROR,
    deleteDetailAction,
    deleteDetailReducer,
    deleteDetailErrorReducer,
    deleteDetailSuccessReducer,
    deleteDetailSaga,
} = deleteDetailRedux;

const rescheduleRedux = serverInteraction('RESCHEDULE', API.post, 'reschedule', false);

export const {
    RESCHEDULE_ACTION,
    RESCHEDULE_SUCCESS,
    RESCHEDULE_ERROR,
    rescheduleAction,
    rescheduleReducer,
    rescheduleErrorReducer,
    rescheduleSuccessReducer,
    rescheduleSaga,
} = rescheduleRedux;

const searchCustomerMap = {
    [CUSTOMER_SEARCH_ACTION]: customerSearchReducer,
    [CUSTOMER_SEARCH_SUCCESS]: customerSearchSuccessReducer,
    [CUSTOMER_SEARCH_ERROR]: customerSearchErrorReducer,

    [CUSTOMER_ADD_SUCCESS]: customerAddSuccessReducer,
    [CUSTOMER_ADD_ERROR]: customerAddErrorReducer,
    [CUSTOMER_ADD_ACTION]: customerAddReducer,

    [SCHEDULE_SUCCESS]: scheduleSuccessReducer,
    [SCHEDULE_ERROR]: scheduleErrorReducer,
    [SCHEDULE_ACTION]: scheduleReducer,

    [ADD_DETAIL_SUCCESS]: addDetailSuccessReducer,
    [ADD_DETAIL_ERROR]: addDetailErrorReducer,
    [ADD_DETAIL_ACTION]: addDetailReducer,

    [DELETE_DETAIL_SUCCESS]: deleteDetailSuccessReducer,
    [DELETE_DETAIL_ERROR]: deleteDetailErrorReducer,
    [DELETE_DETAIL_ACTION]: deleteDetailReducer,

    [RESCHEDULE_SUCCESS]: rescheduleSuccessReducer,
    [RESCHEDULE_ERROR]: rescheduleErrorReducer,
    [RESCHEDULE_ACTION]: rescheduleReducer,

    [TREATMENT_DELETE_ACTION]: treatmentDeleteReducer,
    [TREATMENT_DELETE_SUCCESS]: treatmentDeleteSuccessReducer,
    [TREATMENT_DELETE_ERROR]: treatmentDeleteErrorReducer,


    [NEXT_COMMAND_ID_ACTION]:  nextCommandIdReducer,
    [NEXT_COMMAND_ID_SUCCESS]: nextCommandIdSuccessReducer,
    [NEXT_COMMAND_ID_ERROR]:   nextCommandIdErrorReducer,

    [CLOSE_MODAL]: modalReducer,

    [RESTORE_CUSTOMER_ACTION]: restoreCustomerSearchReducer,

    [SET_CURRENT_COMMAND]: setCurrentCommandReducer,
};

export default function reducer(state = schedulerState, action) {
    const actionFn = searchCustomerMap[action.type];
    if (actionFn) {
        return actionFn(state, action) || state;
    }
    return state;
}

export function restoreCustomerSearchAction(customers) {
    return {
        type: RESTORE_CUSTOMER_ACTION,
        customers: customers.map((customer) => ({
            ...customer,
            autoSelect: false,
        })),
    };
}

function* deleteDetailSuccessSaga() {
    yield notifyUser('Serviço excluido com sucesso');
}

function* addDetailSuccessSaga() {
    yield notifyUser('Serviço adicionado com sucesso');
}

function* scheduleSuccessSaga() {
    notifyUser('Agendamento efetuado com sucesso');
    yield put({
        type: CLOSE_MODAL,
        payload: {
            closeModal: true,
        },
    });
    yield put({
        type: RELOAD_EVENTS,
    });
}

function* scheduleErrorSaga(event) {
    yield notifyUser(`Erro ao salvar agendamento: ${event.payload.message}`);
}

const treatmentDeleteErrorSaga = buildBackendErrorSaga('Agendamento excluido com sucesso!', 'Erro ao efetuar pagamento!', function* successCallback() {
    yield put({
        type: CLOSE_MODAL,
        payload: {
            closeModal: true,
        },
    });
    yield put({
        type: 'RELOAD_EVENTS',
    });
});

export function* rescheduleSuccessSaga() {
    notifyUser('Agendamento deletado com sucesso');
    yield put({
        type: CLOSE_MODAL,
        payload: {
            closeModal: true,
        },
    });
    yield put({
        type: 'RELOAD_EVENTS',
    });
}

export function selectCustomerAction(customer) {
    return {
        type: CUSTOMER_ADD_SUCCESS,
        customer,
    };
}

export function* schedulerSagas() {
    yield all([
        yield debounce(500, CUSTOMER_SEARCH_ACTION, customerSearchSaga),

        yield takeEvery(CALENDAR_EVENTS_LOAD_START_ACTION, nextCommandIdSaga),
        yield takeEvery(NEXT_COMMAND_ID_ACTION, nextCommandIdSaga),

        yield takeEvery(SCHEDULE_ACTION, scheduleSaga),
        yield takeEvery(ADD_DETAIL_ACTION, addDetailSaga),
        yield takeEvery(DELETE_DETAIL_ACTION, deleteDetailSaga),

        yield takeEvery(RESCHEDULE_ACTION, rescheduleSaga),
        yield takeEvery(CUSTOMER_ADD_ACTION, customerAddSaga),

        yield takeEvery(SCHEDULE_SUCCESS, scheduleSuccessSaga),
        yield takeEvery(SCHEDULE_ERROR, scheduleErrorSaga),

        yield takeEvery(ADD_DETAIL_SUCCESS, addDetailSuccessSaga),
        yield takeEvery(ADD_DETAIL_ERROR, scheduleErrorSaga),

        yield takeEvery(DELETE_DETAIL_SUCCESS, deleteDetailSuccessSaga),
        yield takeEvery(DELETE_DETAIL_ERROR, scheduleErrorSaga),

        yield takeEvery(RESCHEDULE_SUCCESS, rescheduleSuccessSaga),

        yield takeEvery(TREATMENT_DELETE_ACTION, treatmentDeleteSaga),
        yield takeEvery(TREATMENT_DELETE_ERROR, treatmentDeleteErrorSaga),
        yield takeEvery(TREATMENT_DELETE_SUCCESS, treatmentDeleteErrorSaga),
    ]);
}
