import {
    call,
    put,
} from 'redux-saga/effects';
import querystring from 'querystring';
import {
    serverResponseToJson,
} from '../../services/ebelle';
import API from '../../services/api';

const snakeToCamel = (str) => str.toLowerCase().replace(/([-_]\w)/g, (group) => group[1].toUpperCase());
const evaluateUrlParameters = (payload, path) => Object.keys(payload)
    .reduce((url, key) => url.replaceAll(`:${key}`, encodeURIComponent(payload[key])), path);

export function sagaBuilder(method = API.post, path, successAction, errorAction, useUrlParam = false) {
    function* saga(action) {
        try {
            const {
                payload,
            } = action;
            const finalPath = useUrlParam ? evaluateUrlParameters(payload, path) : path;
            const respose = yield call(method, finalPath, querystring.stringify(payload));
            try {
                const jsonResponse = serverResponseToJson(respose.data);
                yield put({
                    type: successAction,
                    payload: jsonResponse,
                });
            } catch (e) {
                const message = respose.data || e.message;
                yield put({
                    type: errorAction,
                    payload: {
                        message
                    },
                });
            }
        } catch (e) {
            yield put({
                type: errorAction,
                message: e.message,
            });
        }
    }
    return saga;
}

export function serverInteraction(mainActionName, sagaMethod = API.post,
    sagaPath, storeActionToState = true, useUrlParam = false) {
    const successAction = `${mainActionName}_SUCCESS`;
    const errorAction = `${mainActionName}_ERROR`;
    const actionName = `${mainActionName}_ACTION`;
    const actionFunctionName = snakeToCamel(actionName);
    const loadingPropName = `${snakeToCamel(mainActionName)}Loading`;
    const actionReducerName = `${snakeToCamel(mainActionName)}Reducer`;
    const actionSuccessReducerName = `${snakeToCamel(successAction)}Reducer`;
    const errorPropName = `${snakeToCamel(mainActionName)}LoadingError`;
    const actionErrorReducerName = `${snakeToCamel(errorAction)}Reducer`;

    return {
        [actionName]: actionName,
        [successAction]: successAction,
        [errorAction]: errorAction,
        loadingPropName,
        [actionFunctionName]: (payload) => ({
            type: actionName,
            payload,
        }),
        [actionReducerName]: (state, action) => (storeActionToState ? ({
            ...state,
            ...action.payload,
            [loadingPropName]: true,
        }) : ({
            ...state,
            [loadingPropName]: true,
        })),
        /* Split in several lines to be easier to read */
        [actionSuccessReducerName]: (state, action) => ({
            ...state, // Copy the state
            ...action.payload, // Copy full payload to the state
            [loadingPropName]: false, // set loading to false
            [errorPropName]: null, // Set error to null
        }),
        /* Split in several lines to be easier to read */
        [actionErrorReducerName]: (state, action) => ({
            ...state, // Copy the state
            [loadingPropName]: false, // Set loading to false
            [errorPropName]: action.message, // Set error to the state
        }),
        [`${snakeToCamel(mainActionName)}Saga`]: sagaBuilder(sagaMethod, sagaPath, successAction, errorAction, useUrlParam),
    };
}
