import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AttachMoney from '@material-ui/icons/AttachMoney';
import People from '@material-ui/icons/People';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { initAction } from './redux/store/actions';

import CommisionCard from './componets/Commission';
import Command from './componets/Command';
import Login from './componets/Login';
import Calendar from './componets/Calendar';
import Profile from './componets/Profile';

import 'moment/locale/pt-br';

// import 'react-week-calendar/dist/style.less';
// or import css file
import 'react-week-calendar/dist/style.css';

function mapDispatchToProps(dispatch) {
    return {
        initAction: () => {
            dispatch(initAction());
        },
    };
}
function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
    };
}

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));
export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    useEffect(() => props.initAction(), []);

    const { logedIn } = props;
    const classes = useStyles();

    function Commision() {
        return <CommisionCard />;
    }

    function Cart() {
        return <Command />;
    }

    function ProfileFn() {
        return <Profile />;
    }

    return (
    // <Router basename="/android_asset/index.html#">
      <Router basename="/">
        <>
          <CssBaseline />
          <Paper square className={classes.paper}>
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={Login} />
            <Route path="/android_asset" exact component={Login} />
            <Route path="/index.html" exact component={Login} />
            <Route path="/calendar" exact component={Calendar} />
            <Route path="/commision" exact component={Commision} />
            <Route path="/command" exact component={Cart} />
            <Route path="/profile" exact component={ProfileFn} />
          </Paper>
          { logedIn
      && (
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="open drawer">
            <Link to="/commision" id="commision-menu">
              <AttachMoney />
            </Link>
          </IconButton>
          <Fab color="secondary" aria-label="add" className={classes.fabButton}>
            <Link to="/android_asset" id="calendar-menu">
              <CalendarToday />
            </Link>
          </Fab>
          <div className={classes.grow} />
          <IconButton color="inherit">
            <Link to="/command" id="command-menu">
              <AddShoppingCart />
            </Link>
          </IconButton>
          <IconButton color="inherit">
            <Link to="/profile" id="profile-menu">
              <People />
            </Link>
          </IconButton>
        </Toolbar>
      </AppBar>
      )}
        </>
      </Router>
    );
});
