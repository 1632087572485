import {
    combineReducers,
} from 'redux';

import login from './ducks/login';
import calendar from './ducks/calendar';
import scheduler from './ducks/scheduler';
import commision from './ducks/commision';
import payment from './ducks/payment';

export default combineReducers({
    loginState: login,
    calendarState: calendar,
    scheduleState: scheduler,
    commisionState: commision,
    paymentState: payment,
});
