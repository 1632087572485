import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CutomerTable({
    customers, searchTerm, createCustomer, onSelect, searching, addingCustomer,
}) {
    const classes = useStyles();

    if (customers.length === 1 && customers[0].autoSelect && onSelect) {
        onSelect(customers[0]);
    }

    if (searching || addingCustomer) {
        return <LinearProgress />;
    }

    return (
      <List dense className={classes.root}>
        {customers.map((customer) => (
          <ListItem key={customer.id} button>
            <ListItemText id={`${customer.id}_id`} primary={`${customer.name}`} onClick={() => onSelect && onSelect(customer)} />
          </ListItem>
        ))}
        {!customers.length && searchTerm && (
        <ListItem key="new" button>
          <ListItemText id="new_customer" primary={`(${searchTerm}). Deseja adicionar?`} onClick={() => createCustomer && createCustomer({ name: searchTerm })} />
        </ListItem>
        )}
      </List>
    );
}

CutomerTable.defaultProps = {
    searchTerm: '',
    onSelect: null,
    searching: false,
    addingCustomer: false,
};

CutomerTable.propTypes = {
    customers: PropTypes.array, // eslint-disable-line
    searchTerm: PropTypes.string,
    createCustomer: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    searching: PropTypes.bool,
    addingCustomer: PropTypes.bool,
};
