import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';

import moment from 'moment';
import querystring from 'querystring';
import { otherApis } from '../../../services/api';
import {
    serverResponseToJson,
} from '../../../services/ebelle';

const commisionState = {
    commisions: [],
    receivedList: [],
    totalCommision: 0.00,
    totalReceived: 0.00,
};

export const COMMISION_ACTION = 'COMMISION_ACTION';
export const COMMISION_SUCCESS = 'COMMISION_SUCCESS';
export const COMMISION_ERROR = 'COMMISION_ERROR';
export const RECEIVED_SUCCESS = 'RECEVID_SUCCESS';

// Reducer
function commisionActionReducer(state, action) {
    const {
        payload: {
            commisions,
        },
    } = action;
    return {
        ...state,
        commisions,
        totalCommision: commisions
            .reduce((total, current) => total + parseFloat(current.commisionValue), 0),
    };
}

function receivedActionReducer(state, action) {
    const {
        payload: {
            received,
        },
    } = action;
    return {
        ...state,
        receivedList: received,
        totalReceived: received.reduce((total, current) => total + parseFloat(current.value), 0),
    };
}

const commisionReducerMap = {
    [COMMISION_SUCCESS]: commisionActionReducer,
    [RECEIVED_SUCCESS]: receivedActionReducer,
};

export default function commisionReducer(state = commisionState, action) {
    const actionFn = commisionReducerMap[action.type];

    if (actionFn) {
        return actionFn(state, action) || state;
    }
    return state;
}

export function getCommitionAction(payload) {
    return {
        type: COMMISION_ACTION,
        payload,
    };
}

function* getCommitionSaga(action) {
    try {
        const { payload } = action;
        const respose = yield call(otherApis.post, 'report/commissions/false', querystring.stringify({
            email: payload.logedUser.email,
            password: payload.logedUser.password,
            unit: payload.logedUser.unit,
            user: [payload.logedUser.id],
            startDate: moment(payload.startDate).format('DD/MM/YYYY'),
            endDate: moment(payload.endDate).format('DD/MM/YYYY'),
        }));
        yield put({
            type: COMMISION_SUCCESS,
            payload: {
                commisions: serverResponseToJson(respose.data).map((commision) => ({
                    cashier: commision[0],
                    command: commision[1],
                    treatmentDate: moment(commision[2]).format('DD/MM/YYYY'),
                    paymentDate: moment(commision[3]).format('DD/MM/YYYY'),
                    user: commision[4],
                    customer: commision[5],
                    activity: commision[6],
                    payValue: commision[7],
                    commisionValue: commision[8],
                    paymentType: commision[10],
                })),
            },
        });
    } catch (e) {
        yield put({
            type: COMMISION_ERROR,
            message: e.message,
        });
    }
}

function* getReceivedSaga(action) {
    try {
        const { payload } = action;
        const startStr = encodeURIComponent(moment(payload.startDate).format('DD/MM/YYYY'));
        const endStr = encodeURIComponent(moment(payload.endDate).format('DD/MM/YYYY'));

        const respose = yield call(otherApis.get, `/accountpayable/list_user/${startStr}/${endStr}/0/${payload.logedUser.id}/0?${querystring.stringify({
            email: payload.logedUser.email,
            password: payload.logedUser.password,
        })}`);
        yield put({
            type: RECEIVED_SUCCESS,
            payload: {
                received: serverResponseToJson(respose.data),
            },
        });
    } catch (e) {
        yield put({
            type: COMMISION_ERROR,
            message: e.message,
        });
    }
}

export function* commisionSagas() {
    yield all([
        yield takeEvery(COMMISION_ACTION, getCommitionSaga),
        yield takeEvery(COMMISION_ACTION, getReceivedSaga),
    ]);
}
