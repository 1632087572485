import {
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';

import API, {
    authAction,
} from '../../../services/api';

import {
    serverInteraction,
} from '../sauce';

import {
    APP_INIT_ACTION,
} from '../actions';

const loginRedux = serverInteraction('LOGIN', API.post, 'login_full');

const loginState = {
    email: '',
    password: '',
    logedIn: false,
    user: null,
    company: null,
    permissions: {},
};

export const {
    LOGIN_SUCCESS,
    LOGIN_ACTION,
    LOGIN_ERROR,
    loginSaga,
    loginErrorReducer,
} = loginRedux;

export const LOGIN_SUCCESS_ACTION = LOGIN_SUCCESS;
export const LOGIN_ERROR_PWD_ACTION = 'login-error-pwd';
export const LOGOUT_ACTION = 'logout';

function loginActionReducer(state, action) {
    const {
        payload: {
            email,
            password,
        },
    } = action;
    return {
        ...state,
        loginLoading: true,
        email,
        password,
    };
}

function loginSuccessActionReducer(state, action) {
    const payload = action.payload || action;
    return {
        ...state,
        logedIn: true,
        loginLoading: false,
        user: payload.user || payload.customer,
        company: payload.company,
        permissions: payload.permissions,
    };
}

const loginActionsMap = {
    [LOGIN_ACTION]: loginActionReducer,
    [LOGIN_SUCCESS]: loginSuccessActionReducer,
    [LOGIN_ERROR]: loginErrorReducer,
};

export default function loginReducer(state = loginState, action) {
    const actionFn = loginActionsMap[action.type];

    if (actionFn) {
        return actionFn(state, action) || state;
    }
    return state;
}

export function logoutAction() {
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(payload) {
    return {
        type: LOGIN_ACTION,
        payload: {
            ...payload,
            company: '',
            unit: '',
        },
    };
}

function* loginSuccessSaga(action) {
    const payload = action.payload || action;
    if (payload.user || payload.customer) {
        yield localStorage.setItem('user', JSON.stringify(payload.user || payload.customer));
        yield localStorage.setItem('company', JSON.stringify(payload.company));
        yield localStorage.setItem('permissions', JSON.stringify(payload.permissions));
        yield authAction(action);
    }
}

function* logoutSaga() {
    yield localStorage.removeItem('user');
    yield localStorage.removeItem('company');
    yield localStorage.removeItem('permissions');
    // yield document.location.reload();
    yield document.location.href = '/#/login';
}

function* loginErrorSaga(action) {
    if (action.message === 'Network Error') {
        // eslint-disable-next-line
        alert('Erro ao efetuar login. Verifigue sua connecção de internet');
    } else {
        // eslint-disable-next-line
        alert('Usuário e/ou senha inválidos.');
    }
    yield true;
}

function* appInitSaga() {
    const userAsString = localStorage.getItem('user');
    const companyAsString = localStorage.getItem('company');
    const permissionsAsString = localStorage.getItem('permissions') || '{}';
    if (userAsString) {
        const user = JSON.parse(userAsString);
        const company = JSON.parse(companyAsString);
        const permissions = JSON.parse(permissionsAsString);
        yield put({
            type: LOGIN_SUCCESS,
            user,
            company,
            permissions,
        });
    }
}

export function* loginSagas() {
    yield all([
        yield takeEvery(APP_INIT_ACTION, appInitSaga),
        yield takeEvery(LOGIN_ACTION, loginSaga),
        yield takeEvery(LOGIN_SUCCESS, loginSuccessSaga),
        yield takeEvery(LOGIN_ERROR, loginErrorSaga),
        yield takeEvery(LOGOUT_ACTION, logoutSaga),
    ]);
}
