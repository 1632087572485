import {
    call,
    put,
    takeLatest,
    takeEvery,
    select,
    all,
} from 'redux-saga/effects';
import moment from 'moment';
import 'moment/locale/pt-br';
import querystring from 'querystring';

import API from '../../../services/api';
import { formatDateToServer } from '../../../services/date';
import { LOGIN_SUCCESS_ACTION } from './login';

import {
    serverResponseToJson,
} from '../../../services/ebelle';

const calendarState = {
    events: [],
    customerInCalendar: [],
    calendarEventLoading: false,
    initializing: true,
    calendarDate: moment(),
    showNotification: false,
    notificationType: 'success',
};

export const TreatmentStatus = {
    open : 0,
    missed : 1,
    arrived : 2,
    ready : 3,
    paid : 4,
    deleted : 5,
    confirmed : 6,
    preOpen : 7,
    reSchedule : 8,
    budget : 9,
    reMarked : 10,
    onGoing : 11,
    canceled : 12, // by company,
    reOpen : 13, // by company
    complete : 14, // verde realizado no CRM
    pending : 15, // amarelo ou vermelho pendente no CRM
    readyPaid : 16, // ficiticio que combina paid status and ready status2
    missedPaid : 17, // ficiticio que combina paid status and Missed status2
};

export const CALENDAR_EVENTS_LOAD_ACTION = 'CALENDAR_EVENTS_LOAD_ACTIONS';
export const CALENDAR_EVENTS_LOAD_START_ACTION = 'CALENDAR_EVENTS_LOAD_START_ACTION';
export const RELOAD_EVENTS = 'RELOAD_EVENTS';
export const CALENDAR_EVENTS_LOAD_SUCCESS_ACTION = 'CALENDAR_EVENTS_LOAD_SUCCESS_ACTION';
export const CALENDAR_EVENTS_LOAD_ERROR_ACTION = 'CALENDAR_EVENTS_LOAD_ERROR_ACTION';
export const CALENDAR_DATE_CHANGE_ACTION = 'RELOAD_EVENTS';

export const ACTIVITY_LOAD_SUCCESS_ACTION = 'ACTIVITY_LOAD_SUCCESS_ACTION';
export const ACTIVITY_LOAD_ERROR_ACTION = 'ACTIVITY_LOAD_ERROR_ACTION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

export function changeDateAction(date) {
    return {
        type: CALENDAR_DATE_CHANGE_ACTION,
        calendarDate: moment(date),
    };
}

export function closeNoticationAction() {
    return {
        type: CLOSE_NOTIFICATION,
    };
}

export function closeNoticationReducer(state) {
    return {
        ...state,
        showNotification: false,
    };
}

export function upateTreatmentsAction(event) {
    return {
        type: CALENDAR_DATE_CHANGE_ACTION,
        event,
    };
}

export function calendarLoadErrorReducer(state) {
    return {
        ...state,
        calendarEventLoading: false,
    };
}

function calendarLoadReducer(state, payload) {
    return {
        ...state,
        calendarEventLoading: false,
        initializing: false,
        events: payload.events
            .map((event) => ({
                ...event,
                uid: event.id,
                value: event.title.replace('<br/>', '\n'),
                start: moment(event.start),
                end: moment(event.end),
            })),
        customerInCalendar: payload.events
            .map((event) => ({
                id: event.customerId,
                name: event.customerName,
                treatmentStatus: event.status,
            })),
    };
}

function calendarEventStartReducer(state) {
    return {
        ...state,
        calendarEventLoading: true,
    };
}

function activityLoadReducer(state, payload) {
    return {
        ...state,
        activities: payload.activities,
    };
}

function notify(message, notificationType = 'success') {
    return { message, notificationType };
}

const statusChangeMap = {
    // pre-schedule to arived
    [TreatmentStatus.arrived]: (oldEvdent) => notify(`Cliente ${oldEvdent.customerName} chegou!`),
    // pre-schedule to cancel
    [TreatmentStatus.reSchedule]: (oldEvdent) => notify(`O cliente ${oldEvdent.customerName}, agendada para ${oldEvdent.start.format('DD/MM HH:mm')} desmarcou!`, 'error'),
    // pre-schedule to missed
    [TreatmentStatus.missed]: (oldEvdent) => notify(`O cliente ${oldEvdent.customerName}, agendada para ${oldEvdent.start.format('DD/MM HH:mm')} faltou!`, 'warning'),
    [TreatmentStatus.paid]: (oldEvdent) => notify(`O cliente ${oldEvdent.customerName}, pagou!`, 'success'),
};

function notifyIfNeeded(newEvent, oldEvdent) {
    const notifyKey = `${newEvent.status}`;
    const notifyFn = statusChangeMap[notifyKey];
    const notification = notifyFn && notifyFn(oldEvdent);
    return notification;
}

function calendarDateChangeReducer(state, payload) {
    if (payload.event) {
        const eventToChange = state.events.find((event) => event.id === payload.event.id);
        if (eventToChange) {
            //eventToChange.start = moment(payload.event.start);
            //eventToChange.end = moment(payload.event.end);
            const hasTheStatusChanged = eventToChange.status !== payload.event.status;
            const notification = hasTheStatusChanged
                && notifyIfNeeded(payload.event, eventToChange);
            eventToChange.status = payload.event.status;
            return {
                ...state,
                showNotification: notification && notification.message,
                notificationType: notification && notification.notificationType,
                events: state.events.map((event) => ({
                    ...event,
                })),
            };
        }
    }
    return {
        ...state,
        calendarDate: moment(payload.calendarDate),
    };
}

const calendarActionsMap = {
    [CALENDAR_EVENTS_LOAD_SUCCESS_ACTION]: calendarLoadReducer,
    [CALENDAR_EVENTS_LOAD_ERROR_ACTION]: calendarLoadErrorReducer,
    [CALENDAR_EVENTS_LOAD_START_ACTION]: calendarEventStartReducer,
    [CALENDAR_DATE_CHANGE_ACTION]: calendarDateChangeReducer,

    [ACTIVITY_LOAD_SUCCESS_ACTION]: activityLoadReducer,
    [CLOSE_NOTIFICATION]: closeNoticationReducer,
};

export default function calendarReducer(state = calendarState, action) {
    const actionFn = calendarActionsMap[action.type];

    if (actionFn) {
        return actionFn(state, action) || state;
    }
    return state;
}

const getUser = (state) => state.loginState.user;
const getCalendarDate = (state) => state.calendarState.calendarDate;

function* loadEventsSaga() {
    try {
        const calendarDate = yield select(getCalendarDate);
        yield put({
            type: CALENDAR_EVENTS_LOAD_START_ACTION,
            payload: {
                date: formatDateToServer(calendarDate)

            }
        });

        const requestData = yield call(API.post, 'treatments', querystring.stringify({
            start: formatDateToServer(calendarDate),
            end: formatDateToServer(calendarDate.clone().add(3, 'days')),
        }));
        yield put({
            type: CALENDAR_EVENTS_LOAD_SUCCESS_ACTION,
            events: serverResponseToJson(requestData.data),
        });
    } catch (e) {
        yield put({
            type: CALENDAR_EVENTS_LOAD_ERROR_ACTION,
            message: e.message,
        });
    }
}

function* loadActivitiesSaga() {
    try {
        const user = yield select(getUser);

        const requestData = yield call(API.post, 'activities', querystring.stringify({
            company: user.company,
            unit: user.unit,
            user: user.id,
            activityf: '',
        }));

        yield put({
            type: ACTIVITY_LOAD_SUCCESS_ACTION,
            activities: serverResponseToJson(requestData.data).activities,
        });
    } catch (e) {
        yield put({
            type: ACTIVITY_LOAD_ERROR_ACTION,
            message: e.message,
        });
    }
}

export function* calendarSagas() {
    yield all([
        yield takeEvery(LOGIN_SUCCESS_ACTION, loadEventsSaga),
        yield takeLatest(RELOAD_EVENTS, loadEventsSaga),
        yield takeEvery(LOGIN_SUCCESS_ACTION, loadActivitiesSaga),
    ]);
}
