import {
    all,
} from 'redux-saga/effects';

import { loginSagas } from './ducks/login';
import { calendarSagas } from './ducks/calendar';
import { schedulerSagas } from './ducks/scheduler';
import { commisionSagas } from './ducks/commision';
import { paymentSagas } from './ducks/payment';

export default function* rootSaga() {
    yield all([
        loginSagas(),
        schedulerSagas(),
        calendarSagas(),
        commisionSagas(),
        paymentSagas(),
    ]);
}
