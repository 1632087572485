import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loginAction } from '../redux/store/ducks/login';

function mapDispatchToProps(dispatch) {
    return {
        loginAction: (article) => dispatch(loginAction(article)),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        loginLoading: state.loginState.loginLoading,
    };
}

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignIn({ 
    logedIn,
    history,
    loginLoading,
    loginAction 
}) {

    if (logedIn) {
        history.push('/calendar');
    }
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {' '}
          <Typography component="h1" variant="h5">
            {' '}
            Login
            {' '}
          </Typography>
          {' '}
          <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={(event) => setEmail(event.target.value)}
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={(event) => setPassword(event.target.value)}
                autoComplete="current-password"
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                id="login-button"
                className={classes.submit}
                disabled={loginLoading}
                onClick={
                    () => loginAction({
                        email,
                        password,
                    })
                }
            >
              {loginLoading ? 'Aguarde...' : 'Login'}
            </Button>
          </form>
        </div>
      </Container>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignIn);

SignIn.propTypes = {
    logedIn: PropTypes.bool,
    history: PropTypes.array,
    loginLoading: PropTypes.array,
    loginAction: PropTypes.func,
};
