import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CommissionList(props) {
    const classes = useStyles();
    return (
      <List className={classes.root}>
        {props.commisions.map((event) => (
          <ListItem key={event.id}>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${event.customer} [${event.activity}]`} secondary={`R$ ${event.commisionValue} , ${event.paymentDate}`} />
          </ListItem>
        ))}
      </List>
    );
}

CommissionList.propTypes = {
    commisions: PropTypes.array,
};
