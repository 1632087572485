import React from 'react'; 
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    logoutAction,
} from '../redux/store/ducks/login';

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        user: state.loginState.user,
    };
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '80%',
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },

    formControlCustomer: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    selectedCustomerBox: {
        margin: theme.spacing(1),
        minWidth: '200px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '30px',
        left: '50%',
        marginLeft: -12,
    },
}));

function Profile({ logedIn, logout }) {
    if (!logedIn) {
        document.location.href = '#/login';
    }
    const classes = useStyles();
    return (
      <Container component="main" classes={classes.container}>
        <CssBaseline />
        <form className={classes.form} noValidate>
          <span>
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                id="logout"
                onClick={
                    () => logout()
                }
            >
              Sair
            </Button>
          </span>
        </form>
      </Container>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Profile);

Profile.propTypes = {
    logedIn: PropTypes.bool,
    logout: PropTypes.func,
}
