import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';

import CutomerTable from './CutomerTable';


import {
    customerSearchAction,
    scheduleAction,
    customerAddAction,
    restoreCustomerSearchAction,
    restoreModalState,
} from '../../redux/store/ducks/scheduler';

function mapDispatchToProps(dispatch) {
    return {
        customerSearchActionFn: (search) => dispatch(customerSearchAction({ name: search })),
        scheduleAction: (payload) => dispatch(scheduleAction(payload)),
        getHoursAction: () => console.log('todo'),
        customerAddActionFn: (payload) => dispatch(customerAddAction(payload)),
        restoreCustomerSearchActionFn: (customers) => dispatch(restoreCustomerSearchAction(customers)),
        restoreModalState: () => dispatch(restoreModalState()),
    };
}

function mapStateToProps(state) {
    return {
        customers: state.scheduleState.customers || [],
        searching: state.scheduleState.customerSearchLoading,
        addingCustomer: state.scheduleState.customerAddLoading,
        user: state.loginState.user,
    };
}

function getNoCustomerAlert() {
    return <span className="help">
            <Alert severity="warning">Você deve agendar um cliente antes de adicionar items na comanda.</Alert>
        </span>;
}
function customerFixSelect(customersList, customer, setCustomer) {
    const showAlert = customersList.length === 0;
    return showAlert && getNoCustomerAlert() || <span>
            <Select
                id="customer"
                value={customer && customer.id}
                label="Selecione o cliente"
                onChange={(event) => {
                    setCustomer(customersList.find(customer => customer.id == event.target.value))
                }}
            >
          <MenuItem />
            {customersList.map((customer) => <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>)}
         </Select>
        <FormHelperText>Exibindo apenas clientes com agendamento para hoje!</FormHelperText>
    </span>
}


function getCustomerSelectComponent(customerSearchActionFn, setSearch, setFocusField, setCustomer, search, user, customer, customersFixList) {
    if(customersFixList) {
        return customerFixSelect(customersFixList, customer, setCustomer);
    } else {
        return  <TextField 
            id="standard-search" 
            onKeyUp={(event) => customerSearchActionFn(event.target.value, user)} 
            label="Selecione um cliente" 
            type="search" 
            value={search} 
            onChange={(e) => setSearch(e.target.value)} 
            ref={setFocusField} 
        />
    }
}

export function getCustomerComponent(customer, setCustomer, search, setSearch, {
    addingCustomer,
    customerSearchActionFn,
    user,
    customers,
    customersFixList = null,
    restoreCustomerSearchActionFn,
    customerAddActionFn,
    searching
}, classes, setFocusField) {
    if (!customer) {
        const shouldShowCustomerTable = !addingCustomer && !customersFixList;
        return (
            <span>
                <FormControl class={classes.formControlCustomer}>
                    {
                        (addingCustomer && loaderAddingCustomer(search)) || getCustomerSelectComponent(customerSearchActionFn, setSearch, setFocusField, setCustomer, search, user, customer, customersFixList)
                    }
                    {shouldShowCustomerTable &&
                        <CutomerTable
                            customers={customers}
                            onSelect={(customer) => { setCustomer(customer); restoreCustomerSearchActionFn(customers); }}
                            searchTerm={search}
                            createCustomer={customerAddActionFn}
                            searching={searching}
                            addingCustomer={addingCustomer}
                        />}
              </FormControl>
                <FormControl />
          </span>
        );
    }
    return (
        <Chip
            classes={classes.selectedCustomerBox}
            icon={<FaceIcon />}
            label={customer.name}
            onDelete={() => { setCustomer(null); restoreCustomerSearchActionFn(customers); }}
        />
    );
}

function CustomerSelect (props) {
    return getCustomerComponent(props.customer, props.setCustomer, props.search, props.setSearch, props, props.classes, props.setFocusField, props.editing);
}

function loaderAddingCustomer(name) {
    return <span>
            <CircularProgress />
             { `Criando cliente (${name})`}
    </span>
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSelect);
