
import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
    customerSearchAction,
    scheduleAction,
    customerAddAction,
    restoreCustomerSearchAction,
    treatmentDeleteAction,
    restoreModalState,
} from '../redux/store/ducks/scheduler';
import {
    paymentDeleteAction,
    setCustomerAction,
} from '../redux/store/ducks/payment';

import {
    TreatmentStatus,
} from '../redux/store/ducks/calendar';

import {
    ifHasPermission,
    goToCommand,
} from '../services/util';


import { getCustomerComponent } from './customer/CustomerSelect';

import { formatDateToServer } from '../services/date';

const getHourAsStr = (hour) => `${_.padStart(hour.hour, 2, '0')}:${_.padStart(hour.min, 2, '0')}`

function mapDispatchToProps(dispatch) {
    return {
        customerSearchActionFn: (search) => dispatch(customerSearchAction({ name: search })),
        scheduleActionFn: (payload) => dispatch(scheduleAction(payload)),
        // eslint-disable-next-line
        getHoursActionFn: () => console.log('todo'),
        customerAddActionFn: (payload) => dispatch(customerAddAction(payload)),
        restoreCustomerSearchActionFn: (cus) => dispatch(restoreCustomerSearchAction(cus)),
        treatmentDeleteActionFn: (payload) => dispatch(treatmentDeleteAction(payload)),
        paymentDeleteActionFn: (payload) => dispatch(paymentDeleteAction(payload)),
        setCustomerActionToPaymentFn: (payload) => dispatch(setCustomerAction(payload)),
        restoreModalStateFn: () => dispatch(restoreModalState()),
    };
}

function mapStateToProps(state) {
    return {
        logedIn: state.loginState.logedIn,
        calendatDate: state.calendarState.calendarDate,
        activities: state.calendarState.activities || [],
        customers: state.scheduleState.customers || [],
        hours: state.scheduleState.hours || [],
        scheduleSuccess: state.scheduleState.scheduleSuccess,
        searching: state.scheduleState.customerSearchLoading,
        addingCustomer: state.scheduleState.customerAddLoading,
        treatmentDeleteLoading: state.scheduleState.treatmentDeleteLoading,
        scheduleLoading: state.scheduleState.scheduleLoading,
        shouldCloseModal: state.scheduleState.closeModal,
        user: state.loginState.user,
        permissions: state.loginState.permissions,
    };
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '80%',
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },

    formControlCustomer: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    selectedCustomerBox: {
        margin: theme.spacing(1),
        minWidth: '200px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '30px',
        left: '50%',
        marginLeft: -12,
    },
}));

const chip = (text) => <Chip label={text} />;

const ifEditing = (create, edit, isEditing) => (isEditing ? edit : create);

const FormControlEditCreate = ({
    isEditing, classes, children, value,
}) => {
    if (!isEditing) {
        return <FormControl className={classes.formControl}>{children}</FormControl>;
    }
    return chip(value);
};

function Schedule({
    shouldCloseModal,
    logedIn,
    permissions,
    scheduleSuccess,
    restoreModalStateFn,
    closeModal,
    customer: customerProp,
    user: userProp,
    // hours: hoursProp,
    date: dateProp,
    getHoursActionFn,
    hour: hourProp,
    activities,
    service: serviceProp,
    addingCustomer,
    customers,
    treatmentDeleteLoading,
    searching,
    scheduleLoading,
    servicesDescription,
    id,
    status,
    command,
    history,
    customerSearchActionFn,
    setCustomerActionToPaymentFn,
    scheduleActionFn,
    restoreCustomerSearchActionFn,
    customerAddActionFn,
    treatmentDeleteActionFn,
    paymentDeleteActionFn,
}) {
    let searchField = null;
    useEffect(() => {
        setTimeout(() => searchField && searchField.querySelector('input').focus(), 100);
    });

    if (shouldCloseModal) {
        closeModal();
        restoreModalStateFn();
    }

    if (!logedIn || scheduleSuccess) {
        document.location.href = '#/login';
    }
    const classes = useStyles();
    const [customer, setCustomer] = useState(customerProp);
    const [search, setSearch] = useState('');
    // const [readHours, setReadHours] = useState(true);
    const [user, setUser] = useState(userProp.id);
    const [date, setDate] = useState(dateProp || new Date());
    const [service, setSevice] = useState(serviceProp || _.get(activities, '0.id'));
    const [hour, setHour] = useState(hourProp || getHourAsStr(_.get(hourProp, '.0', {})));

    const setFocusField = (input) => { searchField = input; };
    const editing = !!id;
    return (
      <Container component="main" classes={classes.container}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <span>
                  { getCustomerComponent(customer, setCustomer, search, setSearch, {
                      addingCustomer,
                      customerSearchActionFn,
                      user,
                      customers,
                      restoreCustomerSearchActionFn,
                      customerAddActionFn,
                      searching,
                  }, classes, setFocusField, editing) 
                  }
                <FormControl />
                <br />
              </span>
              <FormControlEditCreate isEditing={editing} classes={classes} value={userProp.name}>
                <InputLabel id="demo-simple-select-helper-label">Selecione um Profissional</InputLabel>
                <Select
                    disabled
                    value={user}
                    onChange={(event) => {
                        setUser(event.target.value); getHoursActionFn({
                            date, user, selectedUser: event.target.value, service,
                        });
                    }}
                >
                  <MenuItem value={userProp.id}>{userProp.name}</MenuItem>
                </Select>
                <FormHelperText>.</FormHelperText>
              </FormControlEditCreate>
              <FormControlEditCreate classes={classes} isEditing={editing} value={date.format('DD/MM/YYYY')}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Selecione uma data"
                    value={date}
                    onChange={(value) => {
                        setDate(value); getHoursActionFn({
                            date: value,
                            user,
                            selectedUser: user,
                            service,
                        });
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

                <FormHelperText>.</FormHelperText>
              </FormControlEditCreate>

              <FormControlEditCreate classes={classes} isEditing={editing} value={hour}>
                <TextField
                    label="Selecione um Horario"
                    type="time"
                    value={hour}
                    onChange={(event) => setHour(event.target.value)}
                />
              </FormControlEditCreate>
              <FormControlEditCreate classes={classes} isEditing={editing} value={servicesDescription}>
                <InputLabel id="demo-simple-select-helper-label">Selecione um Serviço</InputLabel>
                <Select
                    id="activity"
                    value={service}
                    onChange={(event) => {
                        setSevice(event.target.value); getHoursActionFn({
                            date, user, selectedUser: user, service: event.target.value,
                        });
                    }}
                >
                  <MenuItem />
                  {activities.map((activity) => <MenuItem key={activity.id} value={activity.id}>{activity.name}</MenuItem>)}
                </Select>
                <FormHelperText>.</FormHelperText>
              </FormControlEditCreate>
              <FormControl />
                {ifHasPermission(<Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={scheduleLoading}
                    onClick={
                        () => scheduleActionFn({
                            email: userProp.email,
                            obs: "Prof. Mobile App",
                            password: userProp.password,
                            customer: customer.id,
                            company: userProp.company,
                            unit: userProp.unit,
                            date: formatDateToServer(date),
                            command: command,
                            hour_start: hour,
                            activity: service,
                            user,
                            logedUser: userProp,
                            id,
                        })
                    }
                >
                    { editing ? 'Salvar' : 'Agendar'}
                </Button>, <span/>, status != TreatmentStatus.paid)
                }
              {ifEditing(<span />,
                <span>
                  <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={treatmentDeleteLoading}
                      className={classes.submit}
                      onClick={
                          () => closeModal()
                      }
                  >
                    Cancelar Edição
                  </Button>
                    {ifHasPermission(<Button
                        fullWidth
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        disabled={treatmentDeleteLoading}
                        className={classes.submit}
                        onClick={
                            () => {
                                if(status != TreatmentStatus.paid) {
                                    treatmentDeleteActionFn({ id });
                                } else {
                                    if(confirm("O atendimento já esta pago, excluir o atendimento excluira o pagamento. Tem certeza que deseja prosseguir?")) {
                                        paymentDeleteActionFn({
                                            command,
                                            customer: customer.id,
                                            date: formatDateToServer(date),
                                        });
                                    }
                                }
                            }
                        }
                    >
                        {  status != TreatmentStatus.paid ? "Excluir" : "Exluir Pagamento" }
                    </Button>, <span/>, permissions.isFinancialManager || status != TreatmentStatus.paid)
                    }
                    {ifHasPermission(<Button
                        fullWidth
                        variant="contained"
                        startIcon={<MonetizationOnIcon />}
                        disabled={treatmentDeleteLoading  || status == TreatmentStatus.paid}
                        className={classes.submit}
                        onClick={() => { 
                            setCustomerActionToPaymentFn(customer);
                            goToCommand(history);
                        }}
                    >
                        Pagar
                    </Button>, <span/>, permissions.isFinancialManager
                    )}
                </span>, editing)}
            </form>
          </div>
          {(treatmentDeleteLoading || scheduleLoading)
            && <CircularProgress size={50} className={classes.buttonProgress} />}
        </MuiPickersUtilsProvider>
      </Container>
    );
}

Schedule.defaultProps = {
    shouldCloseModal: false,
    logedIn: false,
    scheduleSuccess: false,
    customer: null,
    user: null,
    hours: null,
    date: null,
    hour: null,
    activities: [],
    service: null,
    id: null,
    addingCustomer: false,
    treatmentDeleteLoading: false,
    searching: false,
    scheduleLoading: false,
    servicesDescription: '',
};

Schedule.propTypes = {
    restoreModalStateFn: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    getHoursActionFn: PropTypes.func.isRequired,
    customerSearchActionFn: PropTypes.func.isRequired,
    setCustomerActionToPaymentFn: PropTypes.func.isRequired,
    scheduleActionFn: PropTypes.func.isRequired,
    restoreCustomerSearchActionFn: PropTypes.func.isRequired,
    customerAddActionFn: PropTypes.func.isRequired,
    paymentDeleteActionFn: PropTypes.func.isRequired,
    treatmentDeleteActionFn: PropTypes.func.isRequired,
    shouldCloseModal: PropTypes.bool,
    logedIn: PropTypes.bool,
    scheduleSuccess: PropTypes.bool,
    customer: PropTypes.object, // eslint-disable-line
    permissions: PropTypes.object, // eslint-disable-line
    customers: PropTypes.array, // eslint-disable-line
    user: PropTypes.object, // eslint-disable-line
    hours: PropTypes.array, // eslint-disable-line
    date: PropTypes.instanceOf(Date), // eslint-disable-line
    hour: PropTypes.object, // eslint-disable-line
    activities: PropTypes.array, // eslint-disable-line
    service: PropTypes.object, // eslint-disable-line
    addingCustomer: PropTypes.bool,
    treatmentDeleteLoading: PropTypes.bool,
    searching: PropTypes.bool,
    scheduleLoading: PropTypes.bool,
    servicesDescription: PropTypes.string,
    id: PropTypes.any, // eslint-disable-line
    status: PropTypes.any, // eslint-disable-line
    command: PropTypes.string,
    history: PropTypes.array,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Schedule));
