import axios from 'axios';

const domain = 'https://gerirme.app';
// const domain = 'http://ebelle.vilarika.com.br';
//const domain = 'http://ebelle.docker.localhost:82';

// eslint-disable-next-line
const encode = (typeof btoa === 'function') ? btoa : (str) => Buffer.from(str).toString('base64');

const authData = {

};

const api = axios.create({
    baseURL: `${domain}/mobile/api/`,
    transformRequest: [(data, headers) => {
        if (authData.token) {
            // eslint-disable-next-line
            headers.Authorization = `Basic ${authData.token}`;
        }
        return data;
    }],
});

const otherApis = axios.create({
    baseURL: `${domain}`,
    transformRequest: [(data, headers) => {
        if (authData.token) {
            // eslint-disable-next-line
            headers.Authorization = `Basic ${authData.token}`;
        }
        return data;
    }],
});

const authAction = (action) => {
    const payload = action.payload || action;
    const {
        user,
        company,
        customer,
    } = payload;
    authData.user = user || customer;
    authData.company = company;
    authData.token = `${encode(`${authData.user.email}:${authData.user.password}:${authData.user.company}:${authData.user.unit}`)}`;
};

export default api;

export {
    otherApis,
    authAction,
};
